export const loginConstants = {
  en: {
    forgotPassword: {
      confirmPassword: 'Confirm Password',
      passwordsDoNotMatch: "Hmm, those passwords don't match.Give it another try.",
      sendRequest: 'Send Request',
      helpOnTheWay: 'Help is on the way!',
      mailSent: 'An email has been sent to your inbox with further instructions.',
      enterPasswordBelow: 'Enter your new password below.',
      tokenExired: 'Sorry, your token expired!',
      anotherEmailToResetPassword: "Have no fear, we'll email you another one with instructions to reset your password.",
      PasswordReady: 'Your password is ready!',
      loginWithNewPassword: 'You can login to your account with the new password now.',
      forgotYourPassword: 'Forgot your password?',
      hopeMessage: "We've got your back. We'll email you the instructions to reset your password.",
    },

    common: {
      createPassword: 'Create Password',
      minLenth: 'minimum lenght',
      oneLetter: 'One letter',
      oneNumber: 'One number',
      caseSensitive: 'Case sensitive',
      oneOfThese: 'One of these:',
      submit: 'Submit',
      email: 'Email',
      returnToLogin: 'Return to Login',
    },

    registration: {
      orgName: "What's your organization?",
      selectOrganizationContent: 'Select an organization below and enter the access code if required.',
      organization: 'Organization',
      orgPlaceholder: 'Select an organization',
      accessCode: 'Access Code',
      continue: 'Continue',
      contactAdmin: 'Contact your admin for the code',
      or: 'OR',
      registerToAccess: 'Register to access your training content.',
      createUserId: 'Create User Id',
      userIdNotAvailable: "Aw shucks, it looks like someone already has that User ID. Let's try something else.",
      checklistFailed: 'Please include all password requirements.',
      firstName: 'First Name',
      lastName: 'Last Name',
      clickNextToAgree: "By clicking Next, you agree to CourseMill's",
      termsOfUse: 'Terms of Use',
      and: 'and',
      privacyPolicy: 'Privacy Policy.',
    }

  }
} as const;

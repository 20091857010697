export const messages = {
  en: {
    placeholder: {
      title: 'Page Title',
      description: 'This is your page description',
      heading: 'Section heading',
      content:
        'Enter paragraph text here. This is what a text paragraph could look like on this page. Click anywhere in this paragraph to replace it with your own brilliant text. You’re doing a great job creating a learning experience! As you continue to customize this content, consider adding engaging visuals, such as images, videos, questions, and games to enhance comprehension.',
    },
    question: {
      title:
        'Enter your brilliant question here. What knowledge check do you have?',
      option: {
        default: 'Enter option here. Mark the correct answer by selecting it.',
        add: 'Add option',
      },
      tooltip: {
        correct: 'You choose a correct answer',
        incorrect: 'You choose an incorrect answer',
      },
      panel: {
        title: 'Question',
        subtitle: 'Select Question Type',
        options: {
          title: 'OPTIONS',
          case: 'Case sensitive',
        },
      },
      empty: {
        title: {
          content: 'No question entered.',
        },
        option: {
          content: 'No correct answer selected',
        },
        text: {
          content: 'No correct answers have been entered.',
        },
      },
      shortAnswer: {
        title: 'Short Answer',
        input: 'Enter acceptable answers here.',
        inputLearner: 'Enter your answer here.',
      },
      multipleChoice: {
        title: 'Multiple Choice',
      },
      multipleSelect: {
        title: 'Multiple Select',
      },
      submit: 'Submit',
    },
    theme: {
      heading: 'THEME',
      subheading: 'Select a theme:',
    },
    translate: {
      panel: {
        title: 'TRANSLATION',
        pill: 'BETA',
        edit: 'Edit',
        retry: 'Try Again',
        translateNow: 'Translate Now',
        updateNeeded: 'Update Needed',
        button: 'Translate this Page',
        progress: 'Translation in progress',
        upToDate: 'All Translations are up to date',
      },
      modal: {
        title: 'Translate this Page',
        subtitle: 'Select one or more languages.',
        table: {
          header1: 'Target Languages',
          header2: 'Supported Features',
        },
        footer: 'OK',
      },
      restrict: {
        title: 'Read Only Preview',
        content:
          'This translated page preview is read only. Please return to the original language page to edit the content.',
        button: 'Return To Edit Mode',
      },
      caption: '(Optional Caption Here)',
    },
    quiz: {
      youHaveAnswered: 'You have answered',
      questions: 'questions.',
      viewQuestionButton: 'View Unanswered Q1',
      passed: 'PASSED',
      failed: 'FAILED',
      inProgess: 'IN PROGRESS',
      of: 'of',
      attempts: 'ATTEMPTS',
      yourScore: 'Your Score',
      passingScore: 'Passing Score',
      retry: 'Retry',
      applausePassText: 'Congratulations! You passed the quiz!',
      applauseFailText: 'You did not receive a passing score.',
    },
  },
  af: {
    translate: { caption: '(Opsionele onderskrif hier)' },
    question: {
      shortAnswer: {
        title: 'Kort antwoord',
        input: 'Voer hier aanvaarbare antwoorde in.',
        inputLearner: 'Voer u antwoord hier in.',
      },
      multipleChoice: { title: 'Meervoudige keuse' },
      multipleSelect: { title: 'Meervoudige keuse' },
      submit: 'Indien',
    },
    quiz: {
      youHaveAnswered: 'Jy het geantwoord',
      questions: 'vrae.',
      viewQuestionButton: 'Sien Ongewaardeerde Vraag 1',
      passed: 'GEPASSED',
      failed: 'MISLUK',
      inProgess: 'IN PROSES',
      of: 'VAN',
      attempts: 'POGINGE',
      yourScore: 'Jou Punt',
      passingScore: 'Slaagsyfer',
      retry: 'Probeer weer',
    },
  },
  sq: {
    translate: { caption: '(Titulli opsional këtu)' },
    question: {
      shortAnswer: {
        title: 'Përgjigja e shkurtër',
        input: 'Vendosni përgjigje të pranueshme këtu.',
        inputLearner: 'Vendosni përgjigjen tuaj këtu.',
      },
      multipleChoice: { title: 'Zgjedhje e shumfishte' },
      multipleSelect: { title: 'Zgjidhni shumëfish' },
      submit: 'Paraqes',
    },
    quiz: {
      youHaveAnswered: 'Keni përgjigjur',
      questions: 'pyetjet.',
      viewQuestionButton: 'Shiko Pyetjen e Pabesuar 1',
      passed: 'KALUAR',
      failed: 'DËSHTUAR',
      inProgess: 'NË PROGRES',
      of: 'NË',
      attempts: 'TË PËRPJETUARA',
      yourScore: 'Pikët Tuaja',
      passingScore: 'Pikë Kalimi',
      retry: 'Provo përsëri',
    },
  },
  am: {
    translate: { caption: '(ከተፈለገ የማረጋገጫ ጽሑፍ እዚህ)' },
    question: {
      shortAnswer: {
        title: 'አጭር መልስ',
        input: 'ተቀባይነት ያላቸውን መልሶች እዚህ ያስገቡ.',
        inputLearner: 'መልስዎን እዚህ ያስገቡ.',
      },
      multipleChoice: { title: 'ብዙ ምርጫ' },
      multipleSelect: { title: 'ብዙ ምረጥ' },
      submit: 'ያስገቡ',
    },
    quiz: {
      youHaveAnswered: 'ቃለፀሀፉ፡',
      questions: 'ጥያቄዎች፡',
      viewQuestionButton: 'የምረጃ ጥያቄ 1 ይንቀበል',
      passed: 'ተልተህ',
      failed: 'የተሳሳተ',
      inProgess: 'በእያንዳንዱ',
      of: 'ከፍ',
      attempts: 'የድጋሚዎች፡',
      yourScore: 'የገበያችሁ፡',
      passingScore: 'የተሳሳተ ገበያችሁ፡',
      retry: 'እንዴት ይጠቀማል',
    },
  },
  ar: {
    translate: { caption: '(شرح اختياري هنا)' },
    question: {
      shortAnswer: {
        title: 'اجابة قصيرة',
        input: 'أدخل إجابات مقبولة هنا.',
        inputLearner: 'أدخل إجابتك هنا.',
      },
      multipleChoice: { title: 'متعدد الخيارات' },
      multipleSelect: { title: 'مجموعة مختارة متعددة' },
      submit: 'يُقدِّم',
    },
    quiz: {
      youHaveAnswered: 'لقد أجبت',
      questions: 'أسئلة.',
      viewQuestionButton: 'عرض السؤال غير المجاب عنه 1',
      passed: 'ناجح',
      failed: 'فاشل',
      inProgess: 'قيد التقدم',
      of: 'من',
      attempts: 'محاولات',
      yourScore: 'نقاطك',
      passingScore: 'الدرجة الناجحة',
      retry: 'أعد المحاولة',
    },
  },
  hy: {
    translate: { caption: '(Ընտրանքային վերնագիր այստեղ)' },
    question: {
      shortAnswer: {
        title: 'Կարճ պատասխանն',
        input: 'Մուտքագրեք ընդունելի պատասխաններ այստեղ:',
        inputLearner: 'Մուտքագրեք ձեր պատասխանը այստեղ:',
      },
      multipleChoice: { title: 'Բազմակի ընտրություն' },
      multipleSelect: { title: 'Բազմակի ընտրություն' },
      submit: 'Ներկայացնել',
    },
    quiz: {
      youHaveAnswered: 'Դուք համալիրված եք',
      questions: 'հարցերը:',
      viewQuestionButton: 'Դիտել Չպատասխանված Հարց 1',
      passed: 'ՀԱՌՐԱՍՏՈՒՄ Է',
      failed: 'ՈՐՈՆՏ Է',
      inProgess: 'ԳԻՆԱ ՄԱՍՆԱԾ Է',
      of: 'ՈՒՇ',
      attempts: 'ՀՓԱՌՏԱՆՑՆԵՐ',
      yourScore: 'Ձեր Միավորը',
      passingScore: 'ԱՆՎԱՆ ՄՈՒԱՐ',
      retry: 'Կրկին փորձեք',
    },
  },
  az: {
    translate: { caption: '(İsteğe bağlı başlıq burada)' },
    question: {
      shortAnswer: {
        title: 'Qısa cavab',
        input: 'Qəbul olunan cavabları buraya daxil edin.',
        inputLearner: 'Cavabınızı buraya daxil edin.',
      },
      multipleChoice: { title: 'Çoxlu seçim' },
      multipleSelect: { title: 'Birdən çox seçin' },
      submit: 'təqdim',
    },
    quiz: {
      youHaveAnswered: 'Siz cavab vermisiniz',
      questions: 'sual.',
      viewQuestionButton: 'Cavabsız Suala bax 1',
      passed: 'KEÇDİ',
      failed: 'KEÇMƏDİ',
      inProgess: 'İŞLƏNİR',
      of: '-ın',
      attempts: 'CƏHD',
      yourScore: 'Sizin Nəticəniz',
      passingScore: 'Köhnə Nəticə',
      retry: 'Təkrar cəhd edin',
    },
  },
  bn: {
    translate: { caption: '(এখানে al চ্ছিক ক্যাপশন)' },
    question: {
      shortAnswer: {
        title: 'সংক্ষিপ্ত উত্তর',
        input: 'এখানে গ্রহণযোগ্য উত্তর লিখুন।',
        inputLearner: 'আপনার উত্তর এখানে লিখুন।',
      },
      multipleChoice: { title: 'বহু নির্বাচনী' },
      multipleSelect: { title: 'একাধিক নির্বাচন' },
      submit: 'জমা দিন',
    },
    quiz: {
      youHaveAnswered: 'আপনি উত্তর দিয়েছেন',
      questions: 'প্রশ্নগুলি.',
      viewQuestionButton: 'অউত্তরিত প্রশ্ন 1 দেখুন',
      passed: 'উত্তীর্ণ',
      failed: 'ব্যর্থ',
      inProgess: 'চলমান',
      of: 'এর',
      attempts: 'প্রয়াস',
      yourScore: 'আপনার স্কোর',
      passingScore: 'প্রাপ্তি স্কোর',
      retry: 'আবার চেষ্টা করুন',
    },
  },
  bs: {
    translate: { caption: '(Neobavezno naslov ovdje)' },
    question: {
      shortAnswer: {
        title: 'Kratak odgovor',
        input: 'Ovdje unesite prihvatljive odgovore.',
        inputLearner: 'Ovdje unesite svoj odgovor.',
      },
      multipleChoice: { title: 'Više izbora' },
      multipleSelect: { title: 'Višestruki izbor' },
      submit: 'Predati',
    },
    quiz: {
      youHaveAnswered: 'Odgovorili ste na',
      questions: 'pitanja.',
      viewQuestionButton: 'Pogledajte neodgovoreno pitanje 1',
      passed: 'POLOŽENO',
      failed: 'NIJE POLOŽENO',
      inProgess: 'U TOKU JE',
      of: 'od',
      attempts: 'pokušaja',
      yourScore: 'Vaš rezultat',
      passingScore: 'Prolazni rezultat',
      retry: 'Ponovo',
    },
  },
  bg: {
    translate: { caption: '(Незадължително надпис тук)' },
    question: {
      shortAnswer: {
        title: 'Кратък отговор',
        input: 'Въведете приемливи отговори тук.',
        inputLearner: 'Въведете отговора си тук.',
      },
      multipleChoice: { title: 'Многоброен избор' },
      multipleSelect: { title: 'Множество SELECT' },
      submit: 'Изпращане',
    },
    quiz: {
      youHaveAnswered: 'Вие отговорихте на',
      questions: 'въпроси.',
      viewQuestionButton: 'Преглед на неотговорен въпрос 1',
      passed: 'СПРАВИ СЕ',
      failed: 'НЕУСПЕХ',
      inProgess: 'В ПРОЦЕС НА',
      of: 'ОТ',
      attempts: 'ОПИТИ',
      yourScore: 'Вашият резултат',
      passingScore: 'Преминаващ резултат',
      retry: 'Опитай отново',
    },
  },
  ca: {
    translate: { caption: '(Subtítol opcional aquí)' },
    question: {
      shortAnswer: {
        title: 'Resposta curta',
        input: 'Introduïu aquí respostes acceptables.',
        inputLearner: 'Introduïu la vostra resposta aquí.',
      },
      multipleChoice: { title: 'Múltiples opcions' },
      multipleSelect: { title: 'Selecció múltiple' },
      submit: 'Presentar',
    },
    quiz: {
      youHaveAnswered: 'Heu respost',
      questions: 'preguntes.',
      viewQuestionButton: 'Veure Pregunta Sense Respondre 1',
      passed: 'APROVAT',
      failed: 'SUSPÈS',
      inProgess: 'EN CURS',
      of: 'DE',
      attempts: 'INTENTS',
      yourScore: 'La vostra puntuació',
      passingScore: 'Puntuació de pas',
      retry: 'Torna-ho a provar',
    },
  },
  zh: {
    translate: { caption: '（在这里可选标题）' },
    question: {
      shortAnswer: {
        title: '简短的答案',
        input: '在此处输入可接受的答案。',
        inputLearner: '在这里输入您的答案。',
      },
      multipleChoice: { title: '多项选择' },
      multipleSelect: { title: '多个选择' },
      submit: '提交',
    },
    quiz: {
      youHaveAnswered: '您已回答',
      questions: '问题。',
      viewQuestionButton: '查看未回答的问题1',
      passed: '已通过',
      failed: '未通过',
      inProgess: '进行中',
      of: '的',
      attempts: '尝试',
      yourScore: '您的分数',
      passingScore: '及格分数',
      retry: '重试',
    },
  },
  hr: {
    translate: { caption: '(Opcijski opis ovdje)' },
    question: {
      shortAnswer: {
        title: 'Kratak odgovor',
        input: 'Ovdje unesite prihvatljive odgovore.',
        inputLearner: 'Unesite svoj odgovor ovdje.',
      },
      multipleChoice: { title: 'Više izbora' },
      multipleSelect: { title: 'Višestruki odabir' },
      submit: 'podnijeti',
    },
    quiz: {
      youHaveAnswered: 'Odgovorili ste',
      questions: 'pitanja.',
      viewQuestionButton: 'Pogledajte Neodgovoreno Pitanje 1',
      passed: 'PROŠAO',
      failed: 'NIJE PROŠAO',
      inProgess: 'U TIJEKU',
      of: 'OD',
      attempts: 'Pokušaji',
      yourScore: 'Vaš rezultat',
      passingScore: 'Prolazni rezultat',
      retry: 'Ponovno pokušaj',
    },
  },
  cs: {
    translate: { caption: '(Volitelný titulek zde)' },
    question: {
      shortAnswer: {
        title: 'Stručná odpověď',
        input: 'Zde zadejte přijatelné odpovědi.',
        inputLearner: 'Zde zadejte svou odpověď.',
      },
      multipleChoice: { title: 'Více možností' },
      multipleSelect: { title: 'Více výběru' },
      submit: 'Předložit',
    },
    quiz: {
      youHaveAnswered: 'Odpověděli jste na',
      questions: 'otázek.',
      viewQuestionButton: 'Zobrazit nezodpovězenou otázku 1',
      passed: 'SPLNĚNO',
      failed: 'NESPĚNO',
      inProgess: 'V PRŮBĚHU',
      of: 'Z',
      attempts: 'POKUSŮ',
      yourScore: 'Vaše skóre',
      passingScore: 'Minimální skóre',
      retry: 'Zkus to znovu',
    },
  },
  da: {
    translate: { caption: '(Valgfri billedtekst her)' },
    question: {
      shortAnswer: {
        title: 'Kort svar',
        input: 'Indtast acceptable svar her.',
        inputLearner: 'Indtast dit svar her.',
      },
      multipleChoice: { title: 'Flere valg' },
      multipleSelect: { title: 'Flere vælg' },
      submit: 'Indsend',
    },
    quiz: {
      youHaveAnswered: 'Du har svaret',
      questions: 'spørgsmål.',
      viewQuestionButton: 'Vis ubesvaret spørgsmål 1',
      passed: 'BESTÅET',
      failed: 'IKKE BESTÅET',
      inProgess: 'I GANG',
      of: 'AF',
      attempts: 'FORSØG',
      yourScore: 'Din score',
      passingScore: 'Beståelsespoint',
      retry: 'Prøv igen',
    },
  },
  nl: {
    translate: { caption: '(Optioneel bijschrift hier)' },
    question: {
      shortAnswer: {
        title: 'Kort antwoord',
        input: 'Voer hier acceptabele antwoorden in.',
        inputLearner: 'Voer hier uw antwoord in.',
      },
      multipleChoice: { title: 'Meerkeuze' },
      multipleSelect: { title: 'Meerdere selecteren' },
      submit: 'Indienen',
    },
    quiz: {
      youHaveAnswered: 'U heeft geantwoord',
      questions: 'vragen.',
      viewQuestionButton: 'Bekijk onbeantwoorde vraag 1',
      passed: 'GESLAAGD',
      failed: 'GEFAALD',
      inProgess: 'IN UITVOERING',
      of: 'VAN',
      attempts: 'POGINGEN',
      yourScore: 'Uw score',
      passingScore: 'Voldoende score',
      retry: 'Probeer opnieuw',
    },
  },
  et: {
    translate: { caption: '(Valikuline pealdis siin)' },
    question: {
      shortAnswer: {
        title: 'Lühike vastus',
        input: 'Sisestage vastuvõetavad vastused siia.',
        inputLearner: 'Sisestage oma vastus siia.',
      },
      multipleChoice: { title: 'Valikvastustega' },
      multipleSelect: { title: 'Mitu valimist' },
      submit: 'Esita',
    },
    quiz: {
      youHaveAnswered: 'Olete vastanud',
      questions: 'küsimustest.',
      viewQuestionButton: 'Vaadake vastamata küsimust 1',
      passed: 'SOORITATUD',
      failed: 'MITTESOORITATUD',
      inProgess: 'KÄIV',
      of: 'KOKKU',
      attempts: 'KATSEID',
      yourScore: 'Teie skoor',
      passingScore: 'Läbimise skoor',
      retry: 'Proovi uuesti',
    },
  },
  fa: {
    translate: { caption: '(عنوان اختیاری در اینجا)' },
    question: {
      shortAnswer: {
        title: 'جواب کوتاه',
        input: 'پاسخ های قابل قبول را در اینجا وارد کنید.',
        inputLearner: 'پاسخ خود را اینجا وارد کنید.',
      },
      multipleChoice: { title: 'چند گزینه ای' },
      multipleSelect: { title: 'چند انتخاب کنید' },
      submit: 'ارسال',
    },
    quiz: {
      youHaveAnswered: 'شما پاسخ داده‌اید',
      questions: 'سوال.',
      viewQuestionButton: 'مشاهده سوال بدون پاسخ 1',
      passed: 'قبول شده',
      failed: 'ناکام شده',
      inProgess: 'در حال انجام',
      of: 'از',
      attempts: 'تلاش',
      yourScore: 'نمره شما',
      passingScore: 'نمره قبولی',
      retry: 'دوباره تلاش کنید',
    },
  },
  tl: {
    translate: { caption: '(Opsyonal na caption dito)' },
    question: {
      shortAnswer: {
        title: 'Maikling sagot',
        input: 'Ipasok ang mga katanggap -tanggap na sagot dito.',
        inputLearner: 'Ipasok ang iyong sagot dito.',
      },
      multipleChoice: { title: 'Maraming pagpipilian' },
      multipleSelect: { title: 'Maramihang Piliin' },
      submit: 'Ipasa',
    },
    quiz: {
      youHaveAnswered: 'Kayo ay sumagot',
      questions: 'mga tanong.',
      viewQuestionButton: 'Tingnan ang Hindi Sumasagot na Tanong 1',
      passed: 'PUMASA',
      failed: 'HINDI PUMASA',
      inProgess: 'NAGPAPATULOY',
      of: 'NG',
      attempts: 'PAGTATANGKA',
      yourScore: 'Inyong Marka',
      passingScore: 'Markang Kinakailangan',
      retry: 'Subukan muli',
    },
  },
  fi: {
    translate: { caption: '(Valinnainen kuvateksti täällä)' },
    question: {
      shortAnswer: {
        title: 'Lyhyt vastaus',
        input: 'Anna täältä hyväksyttäviä vastauksia.',
        inputLearner: 'Kirjoita vastauksesi tähän.',
      },
      multipleChoice: { title: 'Monivalinta' },
      multipleSelect: { title: 'Useita valinta' },
      submit: 'Lähetä',
    },
    quiz: {
      youHaveAnswered: 'Olet vastannut',
      questions: 'kysymystä.',
      viewQuestionButton: 'Näytä Vastauskysymys 1',
      passed: 'LÄPI',
      failed: 'HYLÄTTY',
      inProgess: 'MENEILLÄÄN',
      of: 'KYSYMYKSESTÄ',
      attempts: 'YRITYSTÄ',
      yourScore: 'Tulosi',
      passingScore: 'Läpipääsyraja',
      retry: 'Yritä uudelleen',
    },
  },
  fr: {
    translate: { caption: '(Légende facultative ici)' },
    question: {
      shortAnswer: {
        title: 'Réponse courte',
        input: 'Entrez ici des réponses acceptables.',
        inputLearner: 'Entrez votre réponse ici.',
      },
      multipleChoice: { title: 'Choix multiple' },
      multipleSelect: { title: 'Plusieurs sélections' },
      submit: 'Soumettre',
    },
    quiz: {
      youHaveAnswered: 'Vous avez répondu',
      questions: 'questions.',
      viewQuestionButton: 'Voir la question non répondu 1',
      passed: 'RÉUSSI',
      failed: 'ÉCHOUÉ',
      inProgess: 'EN COURS',
      of: 'DE',
      attempts: 'TENTATIVES',
      yourScore: 'Votre score',
      passingScore: 'Score de réussite',
      retry: 'Réessayer',
    },
  },
  'fr-CA': {
    translate: { caption: '(Légende facultative ici)' },
    question: {
      shortAnswer: {
        title: 'Réponse courte',
        input: 'Entrez ici des réponses acceptables.',
        inputLearner: 'Entrez votre réponse ici.',
      },
      multipleChoice: { title: 'Choix multiple' },
      multipleSelect: { title: 'Plusieurs sélections' },
      submit: 'Soumettre',
    },
    quiz: {
      youHaveAnswered: 'Vous avez répondu',
      questions: 'questions.',
      viewQuestionButton: 'Voir la question non répondu 1',
      passed: 'RÉUSSI',
      failed: 'ÉCHOUÉ',
      inProgess: 'EN COURS',
      of: 'DE',
      attempts: 'TENTATIVES',
      yourScore: 'Votre score',
      passingScore: 'Score de réussite',
      retry: 'Réessayer',
    },
  },
  ka: {
    translate: { caption: '(არჩევითი წარწერა აქ)' },
    question: {
      shortAnswer: {
        title: 'Მოკლე პასუხი',
        input: 'შეიყვანეთ მისაღები პასუხები აქ.',
        inputLearner: 'შეიყვანეთ თქვენი პასუხი აქ.',
      },
      multipleChoice: { title: 'მრავალჯერადი არჩევანი' },
      multipleSelect: { title: 'მრავალჯერადი შერჩევა' },
      submit: 'დამორჩილება',
    },
    quiz: {
      youHaveAnswered: 'თქვენ უპასუხეთ',
      questions: 'კითხვები.',
      viewQuestionButton: 'დაუკავშირდით უპასუხეს კითხვა 1',
      passed: 'წარმატებულია',
      failed: 'წარუმატებელია',
      inProgess: 'პროცესშია',
      of: '-სადაც',
      attempts: 'ცდელი',
      yourScore: 'თქვენი ქულა',
      passingScore: 'წარმოების ქულა',
      retry: 'ხელახლა ცდილოვნა',
    },
  },
  de: {
    translate: { caption: '(Optionale Bildunterschrift hier)' },
    question: {
      shortAnswer: {
        title: 'Kurze Antwort',
        input: 'Geben Sie hier akzeptable Antworten ein.',
        inputLearner: 'Geben Sie hier Ihre Antwort ein.',
      },
      multipleChoice: { title: 'Mehrfachauswahl' },
      multipleSelect: { title: 'Mehrfach auswählen' },
      submit: 'Einreichen',
    },
    quiz: {
      youHaveAnswered: 'Sie haben geantwortet',
      questions: 'Fragen.',
      viewQuestionButton: 'Unbeantwortete Frage 1 anzeigen',
      passed: 'BESTANDEN',
      failed: 'DURCHGEFALLEN',
      inProgess: 'IN BEARBEITUNG',
      of: 'VON',
      attempts: 'VERSUCHE',
      yourScore: 'Ihr Ergebnis',
      passingScore: 'Bestehensnote',
      retry: 'Erneut versuchen',
    },
  },
  el: {
    translate: { caption: '(Προαιρετική λεζάντα εδώ)' },
    question: {
      shortAnswer: {
        title: 'Σύντομη απάντηση',
        input: 'Εισαγάγετε αποδεκτές απαντήσεις εδώ.',
        inputLearner: 'Εισαγάγετε την απάντησή σας εδώ.',
      },
      multipleChoice: { title: 'Πολλαπλή επιλογή' },
      multipleSelect: { title: 'Πολλαπλή επιλογή' },
      submit: 'υποβάλλουν',
    },
    quiz: {
      youHaveAnswered: 'Έχετε απαντήσει',
      questions: 'ερωτήσεις.',
      viewQuestionButton: 'Δείτε την αναπάντητη ερώτηση 1',
      passed: 'ΠΕΡΑΣΕ',
      failed: 'ΑΠΕΤΥΧΕ',
      inProgess: 'ΣΕ ΕΞΕΛΙΞΗ',
      of: 'ΑΠΟ',
      attempts: 'ΠΡΟΣΠΑΘΕΙΕΣ',
      yourScore: 'Το σκορ σας',
      passingScore: 'Σκορ πέρασματος',
      retry: 'Δοκιμάστε ξανά',
    },
  },
  gu: {
    translate: { caption: '(અહીં વૈકલ્પિક ક tion પ્શન)' },
    question: {
      shortAnswer: {
        title: 'ટૂંકો જવાબ',
        input: 'અહીં સ્વીકાર્ય જવાબો દાખલ કરો.',
        inputLearner: 'તમારો જવાબ અહીં દાખલ કરો.',
      },
      multipleChoice: { title: 'બહુવૈીકલ્પિક' },
      multipleSelect: { title: 'બહુવિધ પસંદ કરો' },
      submit: 'રજૂ કરવું',
    },
    quiz: {
      youHaveAnswered: 'તમે જવાબ આપ્યો છો',
      questions: 'પ્રશ્નો.',
      viewQuestionButton: 'જવાબ આપવામાં આવ્યો પ્રશ્ન 1',
      passed: 'પાસેડ',
      failed: 'ફેલ્યુ',
      inProgess: 'પ્રગતિમાં',
      of: 'ની',
      attempts: 'પ્રયાસો',
      yourScore: 'તમારો સ્કોર',
      passingScore: 'પાસવર્ડ સ્કોર',
      retry: 'ફરીથી પ્રયાસ કરો',
    },
  },
  ht: {
    translate: { caption: '(Si ou vle caption isit la)' },
    question: {
      shortAnswer: {
        title: 'Repons kout',
        input: 'Antre repons ki akseptab isit la.',
        inputLearner: 'Antre repons ou isit la.',
      },
      multipleChoice: { title: 'Chwa miltip' },
      multipleSelect: { title: 'Chwazi miltip' },
      submit: 'Soumèt',
    },
    quiz: {
      youHaveAnswered: 'Ou te reponn',
      questions: 'kesyon yo.',
      viewQuestionButton: 'Gade Kesyon ki pa Reponn 1',
      passed: 'PASE',
      failed: 'PA PASE',
      inProgess: 'AN PWOGRÈ',
      of: 'DE',
      attempts: 'EFA',
      yourScore: 'Pwen ou yo',
      passingScore: 'Pwen Pase',
      retry: 'Reyajiste',
    },
  },
  ha: {
    translate: { caption: '(Zaɓi na zaɓi anan)' },
    question: {
      shortAnswer: {
        title: 'Amsa',
        input: 'Shiga Amsoshin da aka karɓa anan.',
        inputLearner: 'Shigar da amsar da ke nan.',
      },
      multipleChoice: { title: 'Zabi mai yawa' },
      multipleSelect: { title: 'Zabi da yawa' },
      submit: 'Yi biyayya',
    },
    quiz: {
      youHaveAnswered: 'Kuna ku amsa',
      questions: 'tambayoyi.',
      viewQuestionButton: 'Duba Tambaya da Bata Amsa 1',
      passed: 'YI MAGANA',
      failed: 'BANI MAGANA',
      inProgess: 'A KARFAN CIKIN',
      of: 'DAGA',
      attempts: 'CIKIN KWAREWA',
      yourScore: 'Lantarka',
      passingScore: 'Lantarka Ta Kama',
      retry: 'Yi amfani da',
    },
  },
  he: {
    translate: { caption: '(כיתוב אופציונלי כאן)' },
    question: {
      shortAnswer: {
        title: 'תשובה קצרה',
        input: 'הזן כאן תשובות מקובלות.',
        inputLearner: 'הזן את תשובתך כאן.',
      },
      multipleChoice: { title: 'בחירה מרובה' },
      multipleSelect: { title: 'בחירה מרובה' },
      submit: 'שלח',
    },
    quiz: {
      youHaveAnswered: 'עניתם',
      questions: 'שאלות.',
      viewQuestionButton: 'הצג שאלה שלא נענית 1',
      passed: 'עברתם',
      failed: 'נכשלתם',
      inProgess: 'בתהליך',
      of: 'מתוך',
      attempts: 'נסיונות',
      yourScore: 'הציון שלך',
      passingScore: 'ציון עבר',
      retry: 'נסה שוב',
    },
  },
  hi: {
    translate: { caption: '(यहां वैकल्पिक कैप्शन)' },
    question: {
      shortAnswer: {
        title: 'संक्षिप्त जवाब',
        input: 'यहां स्वीकार्य उत्तर दर्ज करें।',
        inputLearner: 'अपना उत्तर यहां दर्ज करें।',
      },
      multipleChoice: { title: 'बहुविकल्पी' },
      multipleSelect: { title: 'बहु चयन' },
      submit: 'प्रस्तुत करना',
    },
    quiz: {
      youHaveAnswered: 'आपने उत्तर दिया है',
      questions: 'सवालों.',
      viewQuestionButton: 'अनस्वरित प्रश्न देखें 1',
      passed: 'पास',
      failed: 'फेल',
      inProgess: 'की प्रगति में',
      of: 'में से',
      attempts: 'प्रयास',
      yourScore: 'आपका स्कोर',
      passingScore: 'उत्तीर्ण स्कोर',
      retry: 'फिर से कोशिश करें',
    },
  },
  hu: {
    translate: { caption: '(Opcionális felirat itt)' },
    question: {
      shortAnswer: {
        title: 'Rövid válasz',
        input: 'Írja be az elfogadható válaszokat ide.',
        inputLearner: 'Írja be a válaszát ide.',
      },
      multipleChoice: { title: 'Több választási lehetőség' },
      multipleSelect: { title: 'Többszörös válogatás' },
      submit: 'Beküldés',
    },
    quiz: {
      youHaveAnswered: 'Válaszoltál',
      questions: 'kérdésekre.',
      viewQuestionButton: 'Nézd meg a megválaszolatlan kérdést 1',
      passed: 'ÁTMENETI',
      failed: 'SIKERTELEN',
      inProgess: 'FOLYAMATBAN',
      of: 'a',
      attempts: 'PRÓBÁK',
      yourScore: 'A pontszámod',
      passingScore: 'Sikerpontszám',
      retry: 'Próbáld újra',
    },
  },
  is: {
    translate: { caption: '(Valfrjáls myndatexti hér)' },
    question: {
      shortAnswer: {
        title: 'Stutt svar',
        input: 'Sláðu inn viðunandi svör hér.',
        inputLearner: 'Sláðu inn svar þitt hér.',
      },
      multipleChoice: { title: 'Margir möguleikar' },
      multipleSelect: { title: 'Margfeldi velja' },
      submit: 'Sendu',
    },
    quiz: {
      youHaveAnswered: 'Þú hefur svarað',
      questions: 'spurningum.',
      viewQuestionButton: 'Skoða ósvaraða spurningu 1',
      passed: 'NÁÐIST',
      failed: 'MISSEST',
      inProgess: 'Í GANGI',
      of: 'AF',
      attempts: 'REYNI',
      yourScore: 'Niðurstaðan þín',
      passingScore: 'Staða til að fara',
      retry: 'Reyndu aftur',
    },
  },
  id: {
    translate: { caption: '(Keterangan opsional di sini)' },
    question: {
      shortAnswer: {
        title: 'Jawaban singkat',
        input: 'Masukkan jawaban yang dapat diterima di sini.',
        inputLearner: 'Masukkan jawaban Anda di sini.',
      },
      multipleChoice: { title: 'Pilihan ganda' },
      multipleSelect: { title: 'Beberapa Pilih' },
      submit: 'Kirim',
    },
    quiz: {
      youHaveAnswered: 'Anda telah menjawab',
      questions: 'pertanyaan.',
      viewQuestionButton: 'Lihat Pertanyaan yang Belum Dijawab 1',
      passed: 'LULUS',
      failed: 'TIDAK LULUS',
      inProgess: 'DALAM PROSES',
      of: 'DARI',
      attempts: 'PERCUBAAN',
      yourScore: 'Skor Anda',
      passingScore: 'Skor Lulus',
      retry: 'Coba lagi',
    },
  },
  ga: {
    translate: { caption: '(Fotheideal roghnach anseo)' },
    question: {
      shortAnswer: {
        title: 'Freagra Gearr',
        input: 'Iontráil freagraí inghlactha anseo.',
        inputLearner: 'Iontráil do fhreagra anseo.',
      },
      multipleChoice: { title: 'Ilroghnach' },
      multipleSelect: { title: 'Il -roghnaigh' },
      submit: 'Cuir isteach',
    },
    quiz: {
      youHaveAnswered: 'Tá tú tar éisdeachta',
      questions: 'ceisteanna.',
      viewQuestionButton: 'Féach ar Ceist nach bhfuil Freagra Uirthi 1',
      passed: 'RITH',
      failed: 'DIÚLTACH',
      inProgess: 'I MBEART',
      of: 'DE',
      attempts: 'TARRTHÁLA',
      yourScore: 'Do Mharc',
      passingScore: 'Marc Táscach',
      retry: 'Déan iarracht arís',
    },
  },
  it: {
    translate: { caption: '(Didascalia opzionale qui)' },
    question: {
      shortAnswer: {
        title: 'Risposta breve',
        input: 'Inserisci risposte accettabili qui.',
        inputLearner: 'Inserisci la tua risposta qui.',
      },
      multipleChoice: { title: 'Scelta multipla' },
      multipleSelect: { title: 'Selezione multipla' },
      submit: 'Invia',
    },
    quiz: {
      youHaveAnswered: 'Hai risposto',
      questions: 'domande.',
      viewQuestionButton: 'Visualizza domanda senza risposta 1',
      passed: 'PROMOSSO',
      failed: 'BOCCIATO',
      inProgess: 'IN CORSO',
      of: 'DI',
      attempts: 'TENTATIVI',
      yourScore: 'Il tuo punteggio',
      passingScore: 'Punteggio di superamento',
      retry: 'Riprova',
    },
  },
  ja: {
    translate: { caption: '（オプションのキャプションはこちら）' },
    question: {
      shortAnswer: {
        title: '簡潔な答え',
        input: 'ここに受け入れ可能な答えを入力してください。',
        inputLearner: 'ここにあなたの答えを入力してください。',
      },
      multipleChoice: { title: '複数の選択肢' },
      multipleSelect: { title: '複数の選択' },
      submit: '送信',
    },
    quiz: {
      youHaveAnswered: '回答しました',
      questions: '質問。',
      viewQuestionButton: '未回答の質問1を表示',
      passed: '合格',
      failed: '不合格',
      inProgess: '進行中',
      of: 'の',
      attempts: '試行',
      yourScore: 'あなたのスコア',
      passingScore: '合格スコア',
      retry: 'もう一度試してみてください',
    },
  },
  kn: {
    translate: { caption: '(ಐಚ್ al ಿಕ ಶೀರ್ಷಿಕೆ ಇಲ್ಲಿ)' },
    question: {
      shortAnswer: {
        title: 'ಸಣ್ಣ ಉತ್ತರ',
        input: 'ಸ್ವೀಕಾರಾರ್ಹ ಉತ್ತರಗಳನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ.',
        inputLearner: 'ನಿಮ್ಮ ಉತ್ತರವನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ.',
      },
      multipleChoice: { title: 'ಬಹು ಆಯ್ಕೆ' },
      multipleSelect: { title: 'ಬಹು ಆಯ್ಕೆ' },
      submit: 'ಸಲ್ಲಿಸು',
    },
    quiz: {
      youHaveAnswered: 'ನೀವು ಉತ್ತರಿಸಿದ್ದೀರಿ',
      questions: 'ಪ್ರಶ್ನೆಗಳು.',
      viewQuestionButton: 'ಯಾವ ಉತ್ತರವಿಲ್ಲದ ಪ್ರಶ್ನೆ 1 ನೋಡಿ',
      passed: 'ಯಶಸ್ಸಾಗಿದೆ',
      failed: 'ವಿಫಲಗೊಂಡಿದೆ',
      inProgess: 'ಪ್ರಗತಿಯಲ್ಲಿದೆ',
      of: 'ನ',
      attempts: 'ಪ್ರಯತ್ನಗಳು',
      yourScore: 'ನಿಮ್ಮ ಅಂಕಗಳು',
      passingScore: 'ಪಾಸ್ ಮಾಡಲು ಅಂಕಗಳು',
      retry: 'ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ',
    },
  },
  kk: {
    translate: { caption: '(Міндетті тақырып)' },
    question: {
      shortAnswer: {
        title: 'Қысқа жауап',
        input: 'Мұнда қолайлы жауаптар енгізіңіз.',
        inputLearner: 'Жауабыңызды мына жерге енгізіңіз.',
      },
      multipleChoice: { title: 'Бірнеше таңдау' },
      multipleSelect: { title: 'Бірнеше рет таңдаңыз' },
      submit: 'Бағыну',
    },
    quiz: {
      youHaveAnswered: 'Сіз жауап бердіңіз',
      questions: 'сұраулар.',
      viewQuestionButton: 'Жауап берілмаған сұрау 1-ді қарау',
      passed: 'СОРАПТАУ',
      failed: 'СОРАБОЗ',
      inProgess: 'ЖҮРІСТЕ',
      of: '-нан',
      attempts: 'ТАЛДАУ',
      yourScore: 'Сіздің нәтиженіз',
      passingScore: 'Қатарлық нәтижесі',
      retry: 'Қайта қатыс',
    },
  },
  ko: {
    translate: { caption: '(여기 옵션 캡션)' },
    question: {
      shortAnswer: {
        title: '짧은 대답',
        input: '여기에 허용되는 답변을 입력하십시오.',
        inputLearner: '여기에 답을 입력하십시오.',
      },
      multipleChoice: { title: '다중 선택' },
      multipleSelect: { title: '다중 선택' },
      submit: '제출하다',
    },
    quiz: {
      youHaveAnswered: '답변하셨습니다',
      questions: '질문.',
      viewQuestionButton: '미응답 질문 1 보기',
      passed: '통과',
      failed: '미통과',
      inProgess: '진행 중',
      of: '중',
      attempts: '시도',
      yourScore: '당신의 점수',
      passingScore: '통과 점수',
      retry: '다시 시도하세요',
    },
  },
  lv: {
    translate: { caption: '(Izvēles paraksts šeit)' },
    question: {
      shortAnswer: {
        title: 'Īsā atbilde',
        input: 'Ievadiet pieļaujamās atbildes šeit.',
        inputLearner: 'Ievadiet savu atbildi šeit.',
      },
      multipleChoice: { title: 'Izvēle ar atbilžu variantu' },
      multipleSelect: { title: 'Vairāku atlasi' },
      submit: 'Iesniegt',
    },
    quiz: {
      youHaveAnswered: 'Jūs esat atbildējuši',
      questions: 'jautājumiem.',
      viewQuestionButton: 'Skatīt neatsauktu jautājumu 1',
      passed: 'IZIET',
      failed: 'NEIZIET',
      inProgess: 'PROGRESĀ',
      of: 'no',
      attempts: 'MĒĠINĀJUMIEM',
      yourScore: 'Jūsu rezultāts',
      passingScore: 'Iziet punktu',
      retry: 'Mēģiniet vēlreiz',
    },
  },
  lt: {
    translate: { caption: '(Neprivaloma antraštė čia)' },
    question: {
      shortAnswer: {
        title: 'Trumpas atsakymas',
        input: 'Čia įveskite priimtinus atsakymus.',
        inputLearner: 'Įveskite savo atsakymą čia.',
      },
      multipleChoice: { title: 'Keli pasirinkimai' },
      multipleSelect: { title: 'Keli pasirinkimas' },
      submit: 'Pateikti',
    },
    quiz: {
      youHaveAnswered: 'Jūs atsakėte',
      questions: 'klausimų.',
      viewQuestionButton: 'Peržiūrėti neatsakytą klausimą 1',
      passed: 'PRAEITAS',
      failed: 'NEPRAEITAS',
      inProgess: 'VYKSTA',
      of: 'iš',
      attempts: 'BANDYMAI',
      yourScore: 'Jūsų rezultatas',
      passingScore: 'Pereinamasis rezultatas',
      retry: 'Bandykite dar kartą',
    },
  },
  mk: {
    translate: { caption: '(Факултативен наслов овде)' },
    question: {
      shortAnswer: {
        title: 'Краток одговор',
        input: 'Внесете прифатливи одговори овде.',
        inputLearner: 'Внесете го вашиот одговор овде.',
      },
      multipleChoice: { title: 'Повеќекратен избор' },
      multipleSelect: { title: 'Повеќекратни Изберете' },
      submit: 'Достави',
    },
    quiz: {
      youHaveAnswered: 'Одговоривте на',
      questions: 'прашања.',
      viewQuestionButton: 'Погледнете неодговорено прашање 1',
      passed: 'ПОЛОЖЕНО',
      failed: 'НЕПОЛОЖЕНО',
      inProgess: 'ВО ТЕЧЕНИЕ',
      of: 'ОД',
      attempts: 'ОСУШТЕНИ ОСВЕРКУВАЊА',
      yourScore: 'Вашиот резултат',
      passingScore: 'Минимален резултат',
      retry: 'Обиди се повторно',
    },
  },
  ms: {
    translate: { caption: '(Kapsyen pilihan di sini)' },
    question: {
      shortAnswer: {
        title: 'Jawapan pendek',
        input: 'Masukkan jawapan yang boleh diterima di sini.',
        inputLearner: 'Masukkan jawapan anda di sini.',
      },
      multipleChoice: { title: 'Pelbagai pilihan' },
      multipleSelect: { title: 'Pelbagai pilih' },
      submit: 'Hantar',
    },
    quiz: {
      youHaveAnswered: 'Anda telah menjawab',
      questions: 'soalan.',
      viewQuestionButton: 'Lihat Soalan yang Belum Dijawab 1',
      passed: 'LULUS',
      failed: 'GAGAL',
      inProgess: 'SEDANG BERLAKU',
      of: 'DARIPADA',
      attempts: 'PERCUBAAN',
      yourScore: 'Markah Anda',
      passingScore: 'Markah Lulus',
      retry: 'Cuba lagi',
    },
  },
  ml: {
    translate: { caption: '(ഇവിടെ ഓപ്ഷണൽ അടിക്കുറിപ്പ്)' },
    question: {
      shortAnswer: {
        title: 'ചെറിയ ഉത്തരം',
        input: 'സ്വീകാര്യമായ ഉത്തരങ്ങൾ ഇവിടെ നൽകുക.',
        inputLearner: 'നിങ്ങളുടെ ഉത്തരം ഇവിടെ നൽകുക.',
      },
      multipleChoice: { title: 'മൾട്ടിപ്പിൾ ചോയ്സ്' },
      multipleSelect: { title: 'ഒന്നിലധികം തിരഞ്ഞെടുക്കുക' },
      submit: 'കീഴടങ്ങുക',
    },
    quiz: {
      youHaveAnswered: 'നിന്ന് നിന്നെയാണ് ഉത്തരിച്ചത്',
      questions: 'ചോദ്യങ്ങള്‍.',
      viewQuestionButton: 'മറുപടിക്കിടന്ന ചോദ്യം 1 കാണുക',
      passed: 'പാസ് ചെയ്തു',
      failed: 'പരാജിതമാക്കി',
      inProgess: 'പുരോഗതിയിലാണ്',
      of: 'ഉള്ള',
      attempts: 'ശ്രമങ്ങള്‍',
      yourScore: 'നിന്നെയുടെ സ്കോറ്',
      passingScore: 'പാസാക്കുന്ന സ്കോറ്',
      retry: 'വീണ്ടും ശ്രയിക്കുക',
    },
  },
  mt: {
    translate: { caption: '(Caption mhux obbligatorju hawn)' },
    question: {
      shortAnswer: {
        title: 'Tweġiba qasira',
        input: 'Daħħal tweġibiet aċċettabbli hawn.',
        inputLearner: 'Daħħal it-tweġiba tiegħek hawn.',
      },
      multipleChoice: { title: 'Għażla multipla' },
      multipleSelect: { title: 'Multiple Select' },
      submit: 'Tissottometti',
    },
    quiz: {
      youHaveAnswered: 'Int għadda',
      questions: 'mistoqsijiet.',
      viewQuestionButton: "Ara il-Mistoqsija li M'Għandekx Tweġiba 1",
      passed: 'SRIDT',
      failed: 'MĦALLILT',
      inProgess: "F'PROGRESS",
      of: 'MINN',
      attempts: 'TENTATTIVI',
      yourScore: 'Puntegġ tiegħek',
      passingScore: 'Puntegġ tal-passaġġ',
      retry: 'Ipprova mill-ġdid',
    },
  },
  mr: {
    translate: { caption: '(येथे पर्यायी मथळा)' },
    question: {
      shortAnswer: {
        title: 'लहान उत्तर',
        input: 'येथे स्वीकार्य उत्तरे प्रविष्ट करा.',
        inputLearner: 'आपले उत्तर येथे प्रविष्ट करा.',
      },
      multipleChoice: { title: 'बहू पर्यायी' },
      multipleSelect: { title: 'एकाधिक निवडा' },
      submit: 'प्रस्तुत करणे',
    },
    quiz: {
      youHaveAnswered: 'तुम्ही उत्तर दिला आहे',
      questions: 'प्रश्नांपैकी.',
      viewQuestionButton: 'उत्तर दिलेल्या प्रश्न 1 पहा',
      passed: 'सोडलेला',
      failed: 'अयशस्वी',
      inProgess: 'प्रगतीत',
      of: 'पैकी',
      attempts: 'प्रयत्ने',
      yourScore: 'तुमच्या गुणांची',
      passingScore: 'सोडलेल्या गुणांची',
      retry: 'पुन्हा प्रयत्न करा',
    },
  },
  mn: {
    translate: { caption: '(Нэмэлт тайлбар энд)' },
    question: {
      shortAnswer: {
        title: 'Товчхондоо',
        input: 'Зөв хариултыг энд оруулна уу.',
        inputLearner: 'Хариултаа энд оруулна уу.',
      },
      multipleChoice: { title: 'Олон сонголт' },
      multipleSelect: { title: 'Олон сонгогч' },
      submit: 'Бууж өгөх',
    },
    quiz: {
      youHaveAnswered: 'Та хариулсан байна',
      questions: 'асуултанд.',
      viewQuestionButton: 'Хариулаагүй асуултыг харах 1',
      passed: 'АМЖИЛТТАЙ',
      failed: 'АМЖИЛТГҮЙ',
      inProgess: 'ЯВДАЛТ',
      of: 'аас',
      attempts: 'ОРООЛГЫН ОНЦЛОГ',
      yourScore: 'Таны оноо',
      passingScore: 'Амжилтын оноо',
      retry: 'Дахиж оролдох',
    },
  },
  no: {
    translate: { caption: '(Valgfri bildetekst her)' },
    question: {
      shortAnswer: {
        title: 'Kort svar',
        input: 'Skriv inn akseptable svar her.',
        inputLearner: 'Skriv inn svaret ditt her.',
      },
      multipleChoice: { title: 'Flere valg' },
      multipleSelect: { title: 'Flere velger' },
      submit: 'Sende inn',
    },
    quiz: {
      youHaveAnswered: 'Du har svart på',
      questions: 'spørsmål.',
      viewQuestionButton: 'Se ubesvart spørsmål 1',
      passed: 'BESTÅTT',
      failed: 'IKKE BESTÅTT',
      inProgess: 'PÅGÅR',
      of: 'AV',
      attempts: 'FORSØK',
      yourScore: 'Din poengsum',
      passingScore: 'Bestått poengsum',
      retry: 'Prøv igjen',
    },
  },
  ps: {
    translate: { caption: '(دلته اختیاري سرلیک)' },
    question: {
      shortAnswer: {
        title: 'لنډ ځواب',
        input: 'دلته منل شوي ځوابونه دننه کړئ.',
        inputLearner: 'خپل ځواب دلته دننه کړئ.',
      },
      multipleChoice: { title: 'زیات انتخاب' },
      multipleSelect: { title: 'ګ multiple غوره' },
      submit: 'وسپارئ',
    },
    quiz: {
      youHaveAnswered: 'تاسو جواب دېږل شوئ',
      questions: 'پوښتنې.',
      viewQuestionButton: 'نه پوښتنه وګورئ 1',
      passed: 'کامیاب شوی',
      failed: 'ناکام شوی',
      inProgess: 'کار په لارښود کیږي',
      of: 'له',
      attempts: 'کوششونه',
      yourScore: 'ستاسو امتیاز',
      passingScore: 'کامیاب شولو امتیاز',
      retry: 'دوباره کوشش کول',
    },
  },
  pl: {
    translate: { caption: '(Podpis opcjonalny tutaj)' },
    question: {
      shortAnswer: {
        title: 'Krótka odpowiedź',
        input: 'Wprowadź tutaj akceptowalne odpowiedzi.',
        inputLearner: 'Wprowadź swoją odpowiedź tutaj.',
      },
      multipleChoice: { title: 'Wielokrotny wybór' },
      multipleSelect: { title: 'Wiele wybranych' },
      submit: 'Składać',
    },
    quiz: {
      youHaveAnswered: 'Odpowiadałeś na',
      questions: 'pytań.',
      viewQuestionButton: 'Zobacz nieudzielone pytanie 1',
      passed: 'ZALICZONY',
      failed: 'NIEZALICZONY',
      inProgess: 'W TRAKCIE',
      of: 'Z',
      attempts: 'PRÓB',
      yourScore: 'Twój wynik',
      passingScore: 'Wynik zdawania',
      retry: 'Spróbuj ponownie',
    },
  },
  pt: {
    translate: { caption: '(Legenda opcional aqui)' },
    question: {
      shortAnswer: {
        title: 'Resposta curta',
        input: 'Digite respostas aceitáveis ​​aqui.',
        inputLearner: 'Digite sua resposta aqui.',
      },
      multipleChoice: { title: 'Múltipla escolha' },
      multipleSelect: { title: 'Seleção múltipla' },
      submit: 'Enviar',
    },
    quiz: {
      youHaveAnswered: 'Você respondeu',
      questions: 'perguntas.',
      viewQuestionButton: 'Ver pergunta não respondida 1',
      passed: 'APROVADO',
      failed: 'REPROVADO',
      inProgess: 'EM ANDAMENTO',
      of: 'DE',
      attempts: 'TENTATIVAS',
      yourScore: 'Sua pontuação',
      passingScore: 'Pontuação mínima',
      retry: 'Tentar novamente',
    },
  },
  pa: {
    translate: { caption: '(ਵਿਕਲਪਿਕ ਕੈਪਸ਼ਨ ਇੱਥੇ)' },
    question: {
      shortAnswer: {
        title: 'ਛੋਟਾ ਜਵਾਬ',
        input: 'ਇੱਥੇ ਸਵੀਕਾਰਯੋਗ ਜਵਾਬ ਦਰਜ ਕਰੋ.',
        inputLearner: 'ਆਪਣਾ ਜਵਾਬ ਇੱਥੇ ਦਰਜ ਕਰੋ.',
      },
      multipleChoice: { title: 'ਬਹੁ - ਚੋਣ' },
      multipleSelect: { title: 'ਮਲਟੀਪਲ ਚੁਣੋ' },
      submit: 'ਜਮ੍ਹਾਂ ਕਰੋ',
    },
    quiz: {
      youHaveAnswered: 'ਤੁਸੀਂ ਜਵਾਬ ਦਿੱਤੇ ਹਨ',
      questions: 'ਸਵਾਲਾਂ ਵਿੱਚ।',
      viewQuestionButton: 'ਨਾ ਜਵਾਬੀ ਸਵਾਲ ਵੇਖੋ 1',
      passed: 'ਪਾਸ ਕੀਤਾ',
      failed: 'ਅਸਫਲ ਹੋਇਆ',
      inProgess: 'ਤਰੱਕੀ ਕਰ ਰਿਹਾ ਹੈ',
      of: 'ਦੇ',
      attempts: 'ਕੋਸ਼ਿਸ਼ਾਂ',
      yourScore: 'ਤੁਹਾਡਾ ਸਕੋਰ',
      passingScore: 'ਪਾਸ ਸਕੋਰ',
      retry: 'ਮੁੜ ਕੋਸ਼ਿਸ਼ ਕਰੋ',
    },
  },
  ro: {
    translate: { caption: '(Subtitrare opțională aici)' },
    question: {
      shortAnswer: {
        title: 'Răspuns scurt',
        input: 'Introduceți răspunsuri acceptabile aici.',
        inputLearner: 'Introduceți răspunsul dvs. aici.',
      },
      multipleChoice: { title: 'Alegere multiplă' },
      multipleSelect: { title: 'Selectare multiplă' },
      submit: 'Trimite',
    },
    quiz: {
      youHaveAnswered: 'Ați răspuns la',
      questions: 'întrebări.',
      viewQuestionButton: 'Afișați întrebarea fără răspuns 1',
      passed: 'TRECUT',
      failed: 'NE-TRECUT',
      inProgess: 'ÎN CURS DE DESFĂȘURARE',
      of: 'din',
      attempts: 'ÎNCERCĂRI',
      yourScore: 'Scorul tău',
      passingScore: 'Scorul minim',
      retry: 'Încearcă din nou',
    },
  },
  ru: {
    translate: { caption: '(Дополнительная подпись здесь)' },
    question: {
      shortAnswer: {
        title: 'Короткий ответ',
        input: 'Введите приемлемые ответы здесь.',
        inputLearner: 'Введите свой ответ здесь.',
      },
      multipleChoice: { title: 'Большой выбор' },
      multipleSelect: { title: 'Несколько выборов' },
      submit: 'Представлять на рассмотрение',
    },
    quiz: {
      youHaveAnswered: 'Вы ответили на',
      questions: 'вопросов.',
      viewQuestionButton: 'Посмотреть неотвеченный вопрос 1',
      passed: 'СДАНО',
      failed: 'НЕ СДАНО',
      inProgess: 'В ПРОЦЕССЕ',
      of: 'из',
      attempts: 'ПОПЫТОК',
      yourScore: 'Ваш результат',
      passingScore: 'Проходной балл',
      retry: 'Попробуйте еще раз',
    },
  },
  sr: {
    translate: { caption: '(Необавезно опис овде)' },
    question: {
      shortAnswer: {
        title: 'Кратак одговор',
        input: 'Овде унесите прихватљиве одговоре.',
        inputLearner: 'Овде унесите свој одговор.',
      },
      multipleChoice: { title: 'Вишеструки избор' },
      multipleSelect: { title: 'Вишеструки одабир' },
      submit: 'прихвати',
    },
    quiz: {
      youHaveAnswered: 'Odgovorili ste na',
      questions: 'pitanja.',
      viewQuestionButton: 'Pogledajte neodgovoreno pitanje 1',
      passed: 'POLOŽENO',
      failed: 'NIJE POLOŽENO',
      inProgess: 'U TOKU JE',
      of: 'od',
      attempts: 'pokušaja',
      yourScore: 'Vaš rezultat',
      passingScore: 'Prolazni rezultat',
      retry: 'Покушајте поново',
    },
  },
  si: {
    translate: { caption: '(මෙහි විකල්ප ශීර්ෂ පා: ය)' },
    question: {
      shortAnswer: {
        title: 'කෙටි පිළිතුර',
        input: 'පිළිගත හැකි පිළිතුරු මෙහි ඇතුළත් කරන්න.',
        inputLearner: 'ඔබේ පිළිතුර මෙතැනට ඇතුළත් කරන්න.',
      },
      multipleChoice: { title: 'බහුවරණ' },
      multipleSelect: { title: 'බහු තෝරන්න' },
      submit: 'ඉදිරිපත් කරන්න',
    },
    quiz: {
      youHaveAnswered: 'ඔබ පිළිතුරු',
      questions: 'ප්රශ්නයක්.',
      viewQuestionButton: 'නිවැරදි ශ්‍රේණිය 1 බලන්න',
      passed: 'සමාන',
      failed: 'අසමාන',
      inProgess: 'ක්‍රියාත්මක',
      of: 'එකට',
      attempts: 'උත්සාහයන්',
      yourScore: 'ඔබගේ ලකුණ',
      passingScore: 'පැහැදිලි ලකුණ',
      retry: 'නැවත උත්සාහ කරන්න',
    },
  },
  sk: {
    translate: { caption: '(Voliteľný titulok tu)' },
    question: {
      shortAnswer: {
        title: 'Stručná odpoveď',
        input: 'Zadajte tu prijateľné odpovede.',
        inputLearner: 'Zadajte svoju odpoveď tu.',
      },
      multipleChoice: { title: 'Viacero možností' },
      multipleSelect: { title: 'Viacnásobný výber' },
      submit: 'Predložiť',
    },
    quiz: {
      youHaveAnswered: 'Odpovedali ste na',
      questions: 'otázok.',
      viewQuestionButton: 'Zobraziť nezodpovedanú otázku 1',
      passed: 'SPLNENÉ',
      failed: 'NESPLNENÉ',
      inProgess: 'V POKRAČOVANÍ',
      of: 'z',
      attempts: 'POKUSOV',
      yourScore: 'Vaše skóre',
      passingScore: 'Minimálne skóre',
      retry: 'Skúsi znovu',
    },
  },
  sl: {
    translate: { caption: '(Neobvezni napis tukaj)' },
    question: {
      shortAnswer: {
        title: 'Kratek odgovor',
        input: 'Tu vnesite sprejemljive odgovore.',
        inputLearner: 'Vnesite svoj odgovor tukaj.',
      },
      multipleChoice: { title: 'Večkratna izbira' },
      multipleSelect: { title: 'Več izbire' },
      submit: 'Oddaj',
    },
    quiz: {
      youHaveAnswered: 'Odgovorili ste',
      questions: 'vprašanj.',
      viewQuestionButton: 'Ogled neodgovorjenega vprašanja 1',
      passed: 'PRESEŽENO',
      failed: 'NEPRESEŽENO',
      inProgess: 'V TEKU',
      of: 'od',
      attempts: 'poskusov',
      yourScore: 'Vaš rezultat',
      passingScore: 'Minimalni rezultat',
      retry: 'Poskusi znova',
    },
  },
  so: {
    translate: { caption: '(Kooxo ikhtiyaari ah halkan)' },
    question: {
      shortAnswer: {
        title: 'Jawaab gaaban',
        input: 'Ku qor jawaabaha la aqbali karo halkan.',
        inputLearner: 'Jawaabtaada geli halkan.',
      },
      multipleChoice: { title: 'Xulasho badan' },
      multipleSelect: { title: 'Xulo badan' },
      submit: 'Isu dhiibid',
    },
    quiz: {
      youHaveAnswered: 'Waxaad qortay',
      questions: "su'aalo.",
      viewQuestionButton: "Eeg su'aal aan jawaabin 1",
      passed: 'LULUS',
      failed: 'MARTAY',
      inProgess: 'KA SHAQAYN',
      of: 'ka',
      attempts: 'isku-soo-baadhista',
      yourScore: 'Xisaabtankaaga',
      passingScore: 'Xisaabtanka guul',
      retry: 'Iska hubi',
    },
  },
  es: {
    translate: { caption: '(Leyenda opcional aquí)' },
    question: {
      shortAnswer: {
        title: 'Respuesta corta',
        input: 'Ingrese respuestas aceptables aquí.',
        inputLearner: 'Ingrese su respuesta aquí.',
      },
      multipleChoice: { title: 'Opción multiple' },
      multipleSelect: { title: 'Seleccionar múltiples' },
      submit: 'Enviar',
    },
    quiz: {
      youHaveAnswered: 'Has respondido',
      questions: 'preguntas.',
      viewQuestionButton: 'Ver pregunta sin responder 1',
      passed: 'APROBADO',
      failed: 'SUSPENDIDO',
      inProgess: 'EN CURSO',
      of: 'de',
      attempts: 'INTENTOS',
      yourScore: 'Tu puntuación',
      passingScore: 'Puntuación de aprobación',
      retry: 'Reintentar',
    },
  },
  sw: {
    translate: { caption: '(Maelezo ya hiari hapa)' },
    question: {
      shortAnswer: {
        title: 'Jibu fupi',
        input: 'Ingiza majibu yanayokubalika hapa.',
        inputLearner: 'Ingiza jibu lako hapa.',
      },
      multipleChoice: { title: 'Chaguo nyingi' },
      multipleSelect: { title: 'Chagua nyingi' },
      submit: 'Wasilisha',
    },
    quiz: {
      youHaveAnswered: 'Umejibu',
      questions: 'maswali.',
      viewQuestionButton: 'Angalia Swali Lisilojibiwa 1',
      passed: 'UMEFAULU',
      failed: 'HUKUFAULU',
      inProgess: 'KATIKA MAENDELEO',
      of: 'kati ya',
      attempts: 'JARIBIO',
      yourScore: 'Alama yako',
      passingScore: 'Alama ya Kufaulu',
      retry: 'Jaribu tena',
    },
  },
  sv: {
    translate: { caption: '(Valfritt bildtext här)' },
    question: {
      shortAnswer: {
        title: 'Kort svar',
        input: 'Ange acceptabla svar här.',
        inputLearner: 'Ange ditt svar här.',
      },
      multipleChoice: { title: 'Flera val' },
      multipleSelect: { title: 'Flera utvalda' },
      submit: 'Skicka in',
    },
    quiz: {
      youHaveAnswered: 'Du har svarat på',
      questions: 'frågor.',
      viewQuestionButton: 'Visa obesvarad fråga 1',
      passed: 'KLARAT',
      failed: 'INTE KLARAT',
      inProgess: 'PÅGÅR',
      of: 'av',
      attempts: 'FÖRSÖK',
      yourScore: 'Ditt resultat',
      passingScore: 'Godkänt resultat',
      retry: 'Försök igen',
    },
  },
  ta: {
    translate: { caption: '(விருப்ப தலைப்பு இங்கே)' },
    question: {
      shortAnswer: {
        title: 'குறுகிய பதில்',
        input: 'ஏற்றுக்கொள்ளக்கூடிய பதில்களை இங்கே உள்ளிடவும்.',
        inputLearner: 'உங்கள் பதிலை இங்கே உள்ளிடவும்.',
      },
      multipleChoice: { title: 'பல தேர்வு' },
      multipleSelect: { title: 'பல தேர்ந்தெடுக்கப்பட்ட' },
      submit: 'சமர்ப்பிக்கவும்',
    },
    quiz: {
      youHaveAnswered: 'நீங்கள் பதிலளித்துள்ளீர்கள்',
      questions: 'கேள்விகள்.',
      viewQuestionButton: 'பதிலளிக்கப்படாத கேள்வி 1 பார்க்க',
      passed: 'கடந்து',
      failed: 'மோசமாக',
      inProgess: 'செயலில்',
      of: 'இல்',
      attempts: 'முயற்சிகள்',
      yourScore: 'உங்கள் மதிப்பு',
      passingScore: 'தேர்ச்சி மதிப்பெண்',
      retry: 'மீண்டும் முயற்சி செய்க',
    },
  },
  te: {
    translate: { caption: '(ఇక్కడ ఐచ్ఛిక శీర్షిక)' },
    question: {
      shortAnswer: {
        title: 'సంక్షిప్త సమాధానం',
        input: 'ఆమోదయోగ్యమైన సమాధానాలను ఇక్కడ నమోదు చేయండి.',
        inputLearner: 'మీ జవాబును ఇక్కడ నమోదు చేయండి.',
      },
      multipleChoice: { title: 'సరైన సమాదానం ఉన్న జవాబుల్లో నుంచి గుర్తించు' },
      multipleSelect: { title: 'బహుళ ఎంపిక' },
      submit: 'సమర్పించండి',
    },
    quiz: {
      youHaveAnswered: 'మీరు సమాధానం ఇచ్చారు',
      questions: 'ప్రశ్నలు.',
      viewQuestionButton: 'వీక్షించడానికి జవాబు లేని ప్రశ్న 1',
      passed: 'గాలిన',
      failed: 'అయితే',
      inProgess: 'జరుగుతున్నది',
      of: 'లో',
      attempts: 'ప్రయత్నాలు',
      yourScore: 'మీ స్కోరు',
      passingScore: 'గాలించుట స్కోరు',
      retry: 'మళ్ళీ ప్రయత్నిచేయండి',
    },
  },
  th: {
    translate: { caption: '(คำบรรยายใต้ภาพที่นี่)' },
    question: {
      shortAnswer: {
        title: 'คำตอบสั้น ๆ',
        input: 'ป้อนคำตอบที่ยอมรับได้ที่นี่',
        inputLearner: 'ป้อนคำตอบของคุณที่นี่',
      },
      multipleChoice: { title: 'ปรนัย' },
      multipleSelect: { title: 'เลือกหลายตัว' },
      submit: 'ส่ง',
    },
    quiz: {
      youHaveAnswered: 'คุณได้ตอบ',
      questions: 'คำถาม',
      viewQuestionButton: 'ดูคำถามที่ยังไม่ได้ตอบ 1',
      passed: 'ผ่าน',
      failed: 'ไม่ผ่าน',
      inProgess: 'กำลังดำเนินการ',
      of: 'จาก',
      attempts: 'ครั้ง',
      yourScore: 'คะแนนของคุณ',
      passingScore: 'คะแนนผ่าน',
      retry: 'ลองอีกครั้ง',
    },
  },
  tr: {
    translate: { caption: '(İsteğe bağlı altyazı burada)' },
    question: {
      shortAnswer: {
        title: 'Kısa cevap',
        input: 'Kabul edilebilir cevapları buraya girin.',
        inputLearner: 'Cevabınızı buraya girin.',
      },
      multipleChoice: { title: 'Çoktan seçmeli' },
      multipleSelect: { title: 'Çoklu Seçim' },
      submit: 'Göndermek',
    },
    quiz: {
      youHaveAnswered: 'Cevap verdiniz',
      questions: 'sorular.',
      viewQuestionButton: 'Cevaplanmamış Soruyu Görüntüle 1',
      passed: 'BAŞARILI',
      failed: 'BAŞARISIZ',
      inProgess: 'DEVAM EDİYOR',
      of: '-den',
      attempts: 'DENEME',
      yourScore: 'Puanınız',
      passingScore: 'Geçme Puanı',
      retry: 'Tekrar dene',
    },
  },
  uk: {
    translate: { caption: '(Необов’язковий підпис тут)' },
    question: {
      shortAnswer: {
        title: 'Коротка відповідь',
        input: 'Введіть тут прийнятні відповіді.',
        inputLearner: 'Введіть свою відповідь тут.',
      },
      multipleChoice: { title: 'Широкий вибір' },
      multipleSelect: { title: 'Кілька вибору' },
      submit: 'Подавати',
    },
    quiz: {
      youHaveAnswered: 'Ви відповіли на',
      questions: 'питань.',
      viewQuestionButton: 'Переглянути невідповідне питання 1',
      passed: 'ЗДАНО',
      failed: 'НЕ ЗДАНО',
      inProgess: 'У ПРОЦЕСІ',
      of: 'з',
      attempts: 'СПРОБИ',
      yourScore: 'Ваш результат',
      passingScore: 'Проходження балу',
      retry: 'Спробуйте ще раз',
    },
  },
  ur: {
    translate: { caption: '(یہاں اختیاری عنوان)' },
    question: {
      shortAnswer: {
        title: 'مختصر جواب',
        input: 'یہاں قابل قبول جوابات درج کریں۔',
        inputLearner: 'اپنا جواب یہاں درج کریں۔',
      },
      multipleChoice: { title: 'کثیر الانتخاب' },
      multipleSelect: { title: 'ایک سے زیادہ منتخب کریں' },
      submit: 'جمع کرائیں',
    },
    quiz: {
      youHaveAnswered: 'آپ نے جواب دیا ہے',
      questions: 'سوالات.',
      viewQuestionButton: 'جواب نہیں دیا گیا سوال دیکھیں 1',
      passed: 'گزر گیا',
      failed: 'ناکام ہوگیا',
      inProgess: 'جاری ہے',
      of: 'میں',
      attempts: 'کوشیشیں',
      yourScore: 'آپ کا نمبر',
      passingScore: 'گزرنے کے نمبر',
      retry: 'مزید کوشش کریں',
    },
  },
  uz: {
    translate: { caption: '(Bu erda ixtiyoriy sarlavha)' },
    question: {
      shortAnswer: {
        title: 'Qisqa javob',
        input: 'Bu erda maqbul javoblarni kiriting.',
        inputLearner: 'Javobingizni bu erda kiriting.',
      },
      multipleChoice: { title: "Ko'p tanlov" },
      multipleSelect: { title: 'Bir nechta tanlang' },
      submit: 'Topshirmoq',
    },
    quiz: {
      youHaveAnswered: 'Siz javob berdingiz',
      questions: 'savollarga.',
      viewQuestionButton: 'Javob bermagan savolni ko‘rish 1',
      passed: 'O‘TGAN',
      failed: 'MUVOFIQQIYATLANMADI',
      inProgess: 'JARAYONDA',
      of: 'dan',
      attempts: 'URINIB KO‘RILGAN NATIJALARI',
      yourScore: 'Sizning natijangiz',
      passingScore: 'O‘TISH BAHOSI',
      retry: 'Qaytadan urinib koʻring',
    },
  },
  vi: {
    translate: { caption: '(Chú thích tùy chọn ở đây)' },
    question: {
      shortAnswer: {
        title: 'Câu trả lời ngắn',
        input: 'Nhập câu trả lời chấp nhận được ở đây.',
        inputLearner: 'Nhập câu trả lời của bạn vào đây.',
      },
      multipleChoice: { title: 'Nhiều lựa chọn' },
      multipleSelect: { title: 'Nhiều lựa chọn' },
      submit: 'Nộp',
    },
    quiz: {
      youHaveAnswered: 'Bạn đã trả lời',
      questions: 'câu hỏi.',
      viewQuestionButton: 'Xem Câu hỏi chưa trả lời 1',
      passed: 'ĐÃ VƯỢT QUA',
      failed: 'KHÔNG ĐẠT',
      inProgess: 'TRONG TIẾN TRÌNH',
      of: 'của',
      attempts: 'LẦN THỬ',
      yourScore: 'Điểm của bạn',
      passingScore: 'Điểm qua môn',
      retry: 'Thử lại',
    },
  },
  cy: {
    translate: { caption: '(Pennawd dewisol yma)' },
    question: {
      shortAnswer: {
        title: 'Ateb byr',
        input: 'Rhowch atebion derbyniol yma.',
        inputLearner: 'Rhowch eich ateb yma.',
      },
      multipleChoice: { title: 'Amlddewis' },
      multipleSelect: { title: 'Dewis lluosog' },
      submit: 'Chyflwynem',
    },
    quiz: {
      youHaveAnswered: 'Rydych wedi ateb',
      questions: 'cwestiynau.',
      viewQuestionButton: 'Gweld cwestiwn heb ei ateb 1',
      passed: 'PASSED',
      failed: 'FAILED',
      inProgess: 'IN PROGRESS',
      of: 'o',
      attempts: 'ATTEMPTS',
      yourScore: 'Eich Sgôr',
      passingScore: 'Sgôr Llwyddo',
      retry: 'Ailbrofi',
    },
  },
  'es-MX': {
    translate: { caption: '(Leyenda opcional aquí)' },
    question: {
      shortAnswer: {
        title: 'Respuesta corta',
        input: 'Ingrese respuestas aceptables aquí.',
        inputLearner: 'Ingrese su respuesta aquí.',
      },
      multipleChoice: { title: 'Opción multiple' },
      multipleSelect: { title: 'Seleccionar múltiples' },
      submit: 'Enviar',
    },
    quiz: {
      youHaveAnswered: 'Has respondido',
      questions: 'preguntas.',
      viewQuestionButton: 'Ver pregunta sin responder 1',
      passed: 'APROBADO',
      failed: 'REPROBADO',
      inProgess: 'EN PROGRESO',
      of: 'de',
      attempts: 'INTENTOS',
      yourScore: 'Tu puntuación',
      passingScore: 'Puntuación de aprobación',
      retry: 'Reintentar',
    },
  },
  'pt-PT': {
    translate: { caption: '(Legenda opcional aqui)' },
    question: {
      shortAnswer: {
        title: 'Resposta curta',
        input: 'Digite respostas aceitáveis aqui.',
        inputLearner: 'Digite sua resposta aqui.',
      },
      multipleChoice: { title: 'Múltipla escolha' },
      multipleSelect: { title: 'Seleção múltipla' },
      submit: 'Enviar',
    },
    quiz: {
      youHaveAnswered: 'Você respondeu',
      questions: 'perguntas.',
      viewQuestionButton: 'Ver pergunta não respondida 1',
      passed: 'APROVADO',
      failed: 'REPROVADO',
      inProgess: 'EM ANDAMENTO',
      of: 'DE',
      attempts: 'TENTATIVAS',
      yourScore: 'Sua pontuação',
      passingScore: 'Pontuação mínima',
      retry: 'Tentar novamente',
    },
  },
  'zh-TW': {
    translate: { caption: '(這裡是可選的標題)' },
    question: {
      shortAnswer: {
        title: '簡短的答案',
        input: '在此處輸入可接受的答案。',
        inputLearner: '在這裡輸入您的答案。',
      },
      multipleChoice: { title: '多項選擇' },
      multipleSelect: { title: '多个选择' },
      submit: '提交',
    },
    quiz: {
      youHaveAnswered: '您已回答',
      questions: '問題。',
      viewQuestionButton: '查看未回答的問題1',
      passed: '已通過',
      failed: '未通過',
      inProgess: '進行中',
      of: '的',
      attempts: '嘗試',
      yourScore: '您的分數',
      passingScore: '及格分數',
      retry: '重試',
    },
  },
  'fa-AF': {
    translate: { caption: '(عنوان اختیاری در اینجا)' },
    question: {
      shortAnswer: {
        title: 'جواب کوتاه',
        input: 'پاسخ های قابل قبول را در اینجا وارد کنید.',
        inputLearner: 'پاسخ خود را اینجا وارد کنید.',
      },
      multipleChoice: { title: 'چند گزینه ای' },
      multipleSelect: { title: 'چند انتخاب کنید' },
      submit: 'ارسال',
    },
    quiz: {
      youHaveAnswered: 'شما پاسخ داده اید',
      lowerOf: 'از',
      questions: 'سوالات.',
      viewQuestionButton: 'مشاهده سوال بدون پاسخ 1',
      passed: 'گذرانده',
      failed: 'ناتوان',
      inProgess: 'در حال پیشرفت',
      of: 'از',
      attempts: 'تلاش ها',
      yourScore: 'امتیاز شما',
      passingScore: 'حداقل امتیاز',
      retry: 'تلاش مجدد',
    },
  },
} as const;

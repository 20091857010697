export interface languageInfo {
  code: LanguageList;
  name: string;
  text: boolean;
  cc: boolean;
  vo: boolean;
  tag?: string;
}

export const TRANSLATION_STATE = {
  ORIGINAL: 'Original',
  WAITING: 'Waiting for Publish',
  TRANSLATED: 'Translated',
  PROCESSING: 'Translating...',
  CHANGED: 'Update Needed',
  PARTIAL: 'Update Needed (Video CC)',
  FAILED: 'Error!',
  REMOVED: 'Deselected',
  NOT_SELECTED: 'Not selected',
} as const;

export type LanguageList =
  | 'en'
  | 'af'
  | 'sq'
  | 'am'
  | 'ar'
  | 'hy'
  | 'az'
  | 'bn'
  | 'bs'
  | 'bg'
  | 'ca'
  | 'zh'
  | 'zh-TW'
  | 'hr'
  | 'cs'
  | 'da'
  | 'nl'
  | 'et'
  | 'fa'
  | 'fa-AF'
  | 'tl'
  | 'fi'
  | 'fr'
  | 'fr-CA'
  | 'ka'
  | 'de'
  | 'el'
  | 'gu'
  | 'ht'
  | 'ha'
  | 'he'
  | 'hi'
  | 'hu'
  | 'is'
  | 'id'
  | 'ga'
  | 'it'
  | 'ja'
  | 'kn'
  | 'kk'
  | 'ko'
  | 'lv'
  | 'lt'
  | 'mk'
  | 'ms'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mn'
  | 'no'
  | 'ps'
  | 'pl'
  | 'pt'
  | 'pt-PT'
  | 'pa'
  | 'ro'
  | 'ru'
  | 'sr'
  | 'si'
  | 'sk'
  | 'sl'
  | 'so'
  | 'es'
  | 'es-MX'
  | 'sw'
  | 'sv'
  | 'ta'
  | 'te'
  | 'th'
  | 'tr'
  | 'uk'
  | 'ur'
  | 'uz'
  | 'vi'
  | 'cy';
export const fullLanguageList: Record<string, languageInfo> = {
  en: {
    code: 'en',
    name: 'English',
    text: true,
    cc: true,
    vo: true,
    tag: '(Original)',
  },
  af: {
    code: 'af',
    name: 'Afrikaans',
    text: true,
    cc: true,
    vo: false,
  },
  sq: {
    code: 'sq',
    name: 'Albanian',
    text: true,
    cc: true,
    vo: false,
  },
  am: {
    code: 'am',
    name: 'Amharic',
    text: true,
    cc: true,
    vo: false,
  },
  ar: {
    code: 'ar',
    name: 'Arabic',
    text: true,
    cc: true,
    vo: true,
  },
  hy: {
    code: 'hy',
    name: 'Armenian',
    text: true,
    cc: true,
    vo: false,
  },
  az: {
    code: 'az',
    name: 'Azerbaijani',
    text: true,
    cc: true,
    vo: false,
  },
  bn: {
    code: 'bn',
    name: 'Bengali',
    text: true,
    cc: true,
    vo: false,
  },
  bs: {
    code: 'bs',
    name: 'Bosnian',
    text: true,
    cc: true,
    vo: false,
  },
  bg: {
    code: 'bg',
    name: 'Bulgarian',
    text: true,
    cc: true,
    vo: false,
  },
  ca: {
    code: 'ca',
    name: 'Catalan',
    text: true,
    cc: true,
    vo: false,
  },
  zh: {
    code: 'zh',
    name: 'Chinese',
    text: true,
    cc: true,
    vo: true,
    tag: '(Simplified)',
  },
  'zh-TW': {
    code: 'zh-TW',
    name: 'Chinese',
    text: true,
    cc: true,
    vo: false,
    tag: '(Traditional)',
  },
  hr: {
    code: 'hr',
    name: 'Croatian',
    text: true,
    cc: true,
    vo: false,
  },
  cs: {
    code: 'cs',
    name: 'Czech',
    text: true,
    cc: true,
    vo: false,
  },
  da: {
    code: 'da',
    name: 'Danish',
    text: true,
    cc: true,
    vo: true,
  },
  nl: {
    code: 'nl',
    name: 'Dutch',
    text: true,
    cc: true,
    vo: true,
  },
  et: {
    code: 'et',
    name: 'Estonian',
    text: true,
    cc: true,
    vo: false,
  },
  fa: {
    code: 'fa',
    name: 'Farsi',
    text: true,
    cc: true,
    vo: false,
    tag: '(Persian)',
  },
  'fa-AF': {
    code: 'fa-AF',
    name: 'Dari',
    text: true,
    cc: true,
    vo: false,
  },
  tl: {
    code: 'tl',
    name: 'Filipino',
    text: true,
    cc: true,
    vo: false,
    tag: '(Tagalog)',
  },
  fi: {
    code: 'fi',
    name: 'Finnish',
    text: true,
    cc: true,
    vo: false,
  },
  fr: {
    code: 'fr',
    name: 'French',
    text: true,
    cc: true,
    vo: true,
  },
  'fr-CA': {
    code: 'fr-CA',
    name: 'French',
    text: true,
    cc: true,
    vo: true,
    tag: '(Canadian)',
  },
  ka: {
    code: 'ka',
    name: 'Georgian',
    text: true,
    cc: true,
    vo: false,
  },
  de: {
    code: 'de',
    name: 'German',
    text: true,
    cc: true,
    vo: true,
  },
  el: {
    code: 'el',
    name: 'Greek',
    text: true,
    cc: true,
    vo: false,
  },
  gu: {
    code: 'gu',
    name: 'Gujarati',
    text: true,
    cc: true,
    vo: false,
  },
  ht: {
    code: 'ht',
    name: 'Haitian',
    text: true,
    cc: true,
    vo: false,
    tag: '(Creole)',
  },
  ha: {
    code: 'ha',
    name: 'Hausa',
    text: true,
    cc: true,
    vo: false,
  },
  he: {
    code: 'he',
    name: 'Hebrew',
    text: true,
    cc: true,
    vo: false,
  },
  hi: {
    code: 'hi',
    name: 'Hindi',
    text: true,
    cc: true,
    vo: true,
  },
  hu: {
    code: 'hu',
    name: 'Hungarian',
    text: true,
    cc: true,
    vo: false,
  },
  is: {
    code: 'is',
    name: 'Icelandic',
    text: true,
    cc: true,
    vo: true,
  },
  id: {
    code: 'id',
    name: 'Indonesian',
    text: true,
    cc: true,
    vo: false,
  },
  ga: {
    code: 'ga',
    name: 'Irish',
    text: true,
    cc: true,
    vo: false,
  },
  it: {
    code: 'it',
    name: 'Italian',
    text: true,
    cc: true,
    vo: true,
  },
  ja: {
    code: 'ja',
    name: 'Japanese',
    text: true,
    cc: true,
    vo: true,
  },
  kn: {
    code: 'kn',
    name: 'Kannada',
    text: true,
    cc: true,
    vo: false,
  },
  kk: {
    code: 'kk',
    name: 'Kazakh',
    text: true,
    cc: true,
    vo: false,
  },
  ko: {
    code: 'ko',
    name: 'Korean',
    text: true,
    cc: true,
    vo: true,
  },
  lv: {
    code: 'lv',
    name: 'Latvian',
    text: true,
    cc: true,
    vo: false,
  },
  lt: {
    code: 'lt',
    name: 'Lithuanian',
    text: true,
    cc: true,
    vo: false,
  },
  mk: {
    code: 'mk',
    name: 'Macedonian',
    text: true,
    cc: true,
    vo: false,
  },
  ms: {
    code: 'ms',
    name: 'Malay',
    text: true,
    cc: true,
    vo: false,
  },
  ml: {
    code: 'ml',
    name: 'Malayalam',
    text: true,
    cc: true,
    vo: false,
  },
  mt: {
    code: 'mt',
    name: 'Maltese',
    text: true,
    cc: true,
    vo: false,
  },
  mr: {
    code: 'mr',
    name: 'Marathi',
    text: true,
    cc: true,
    vo: false,
  },
  mn: {
    code: 'mn',
    name: 'Mongolian',
    text: true,
    cc: true,
    vo: false,
  },
  no: {
    code: 'no',
    name: 'Norwegian',
    text: true,
    cc: true,
    vo: true,
  },
  ps: {
    code: 'ps',
    name: 'Pashto',
    text: true,
    cc: true,
    vo: false,
  },
  pl: {
    code: 'pl',
    name: 'Polish',
    text: true,
    cc: true,
    vo: true,
  },
  pt: {
    code: 'pt',
    name: 'Portuguese',
    text: true,
    cc: true,
    vo: true,
    tag: '(Brazilian)',
  },
  'pt-PT': {
    code: 'pt-PT',
    name: 'Portuguese',
    text: true,
    cc: true,
    vo: true,
  },
  pa: {
    code: 'pa',
    name: 'Punjabi',
    text: true,
    cc: true,
    vo: false,
  },
  ro: {
    code: 'ro',
    name: 'Romanian',
    text: true,
    cc: true,
    vo: true,
  },
  ru: {
    code: 'ru',
    name: 'Russian',
    text: true,
    cc: true,
    vo: true,
  },
  sr: {
    code: 'sr',
    name: 'Serbian',
    text: true,
    cc: true,
    vo: false,
  },
  si: {
    code: 'si',
    name: 'Sinhala',
    text: true,
    cc: true,
    vo: false,
  },
  sk: {
    code: 'sk',
    name: 'Slovak',
    text: true,
    cc: true,
    vo: false,
  },
  sl: {
    code: 'sl',
    name: 'Slovenian',
    text: true,
    cc: true,
    vo: false,
  },
  so: {
    code: 'so',
    name: 'Somali',
    text: true,
    cc: true,
    vo: false,
  },
  es: {
    code: 'es',
    name: 'Spanish',
    text: true,
    cc: true,
    vo: true,
  },
  'es-MX': {
    code: 'es-MX',
    name: 'Spanish',
    text: true,
    cc: true,
    vo: true,
    tag: '(Mexican)',
  },
  sw: {
    code: 'sw',
    name: 'Swahili',
    text: true,
    cc: true,
    vo: false,
  },
  sv: {
    code: 'sv',
    name: 'Swedish',
    text: true,
    cc: true,
    vo: true,
  },
  ta: {
    code: 'ta',
    name: 'Tamil',
    text: true,
    cc: true,
    vo: false,
  },
  te: {
    code: 'te',
    name: 'Telugu',
    text: true,
    cc: true,
    vo: false,
  },
  th: {
    code: 'th',
    name: 'Thai',
    text: true,
    cc: true,
    vo: false,
  },
  tr: {
    code: 'tr',
    name: 'Turkish',
    text: true,
    cc: true,
    vo: true,
  },
  uk: {
    code: 'uk',
    name: 'Ukrainian',
    text: true,
    cc: true,
    vo: false,
  },
  ur: {
    code: 'ur',
    name: 'Urdu',
    text: true,
    cc: true,
    vo: false,
  },
  uz: {
    code: 'uz',
    name: 'Uzbek',
    text: true,
    cc: true,
    vo: false,
  },
  vi: {
    code: 'vi',
    name: 'Vietnamese',
    text: true,
    cc: true,
    vo: false,
  },
  cy: {
    code: 'cy',
    name: 'Welsh',
    text: true,
    cc: true,
    vo: true,
  },
};

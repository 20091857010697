// constants.js
export * from './translate';
export { messages } from './locals';
export { loginConstants } from './loginData';

type ThemeValue = { value: string; text: string };

export const themeList: ThemeValue[] = [
  { value: 'default', text: 'Default' },
  { value: 'heirloom', text: 'Heirloom' },
  { value: 'orange', text: 'Orange' },
  { value: 'onyx', text: 'Onyx' },
  { value: 'philosopher', text: 'Philosopher' },
  { value: 'ubuntu', text: 'Ubuntu' },
  { value: 'unica', text: 'Unica' },
  { value: 'reef', text: 'Reef' },
  { value: 'theme-one', text: 'Theme One' },
  { value: 'affinity', text: 'Affinity' },
  { value: 'dark-theme', text: 'Dark' },
  { value: 'imperial-gold', text: 'Imperial Gold' },
];

export const questionTypes = {
  MULTIPLE_CHOICE: {
    value: 'Multiple Choice',
    icon: 'bi-ui-radios',
    input: 'radio',
  },
  MULTIPLE_SELECT: {
    value: 'Multiple Select',
    icon: 'bi-ui-checkboxes',
    input: 'checkbox',
  },
  SHORT_ANSWER: {
    value: 'Short Answer',
    icon: 'bi-input-cursor-text',
    input: 'text',
  },
};

export const AWS_S3_URL =
  import.meta.env.MODE === 'staging'
    ? 'https://s3.us-east-2.amazonaws.com/content.skcolb.com'
    : import.meta.env.MODE === 'production'
    ? 'https://s3.us-east-2.amazonaws.com/contributor-content.rockstarlearning.com'
    : 'https://s3.us-east-2.amazonaws.com/content.dev-skcolb.com'; //Falling back to dev

  // Portal login page
export const PORTAL_LOGIN_URL =
  import.meta.env.MODE === 'staging'
    ? 'https://stg.portal.elblearning.com'
    : import.meta.env.MODE === 'production'
    ? 'https://portal.elblearning.com'
    // @TODO: update this when we move to staging server
    : 'https://stg.portal.elblearning.com' //https://dev.portal.elblearning.com'; //Falling back to dev
  ;
